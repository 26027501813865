import { cache } from 'react';
import { redirect, notFound } from 'next/navigation';

//* Helpers
import api from '@/helpers/_api';

export const revalidate = 3600;

export const getGlobal = cache(async ({ lang }) => {
	const data = await api.get({ url: 'global-data', lang });

	return data;
});

export const getPage = cache(async ({ slug, lang, params = {} }) => {
	return await api.get({ url: `pages/${slug}`, lang, params }).catch((err) => {
		notFound();
	});
});

export const getBlog = cache(async ({ slug, lang, params = {} }) => {
	return await api.get({ url: `${slug}`, lang, params }).catch((err) => {
		notFound();
	});
});


export const getLocations = async ({ lang }) => {
	const { data } = await api.get({ url: 'locations-section', lang });
	return data;
};

export const getPartners = async () => {
	const { data } = await api.get({ url: 'partners-section' });
	return data;
};

export const getInfo = cache(async ({ slug, lang, params = {} }) => {
	const { data } = await api.get({ url: `${slug}`, lang, params });
	return data;
});

//! Buildings
export const getBuildingInfo = cache(async ({ slug, lang, params = {} }) => {
	return await api.get({ url: `buildings${slug ? `/${slug}` : ''}`, lang, params }).catch((err) => {
		notFound();
	});
});

//! Floors
export const getFloor = cache(async ({ slug, lang, params = {} }) => {
	return await api.get({ url: `buildings/${slug}/floors`, lang, params }).catch((err) => {
		notFound();
	});
});

//! Apartments
export const getApartment = cache(async ({ slug, lang, params = {} }) => {
	return await api.get({ url: `apartments/${slug}`, lang, params }).catch((err) => {
		notFound();
	});
});

//! Meta
export const getMeta = cache(async ({ slug, lang }) => {
	return await api.get({ url: `seo/${slug}`, lang }).catch((err) => {
		redirect('/not-found');
	});
});
